import React, {Component} from 'react'
import PropTypes from "prop-types";
import {connect} from 'react-redux'
import {SubmissionError} from 'redux-form'

import config from '../config'
import {createCookie} from '../libs/cookies'
import {login} from '../reducers/auth'
import LoginForm from '../components/Forms/LoginForm'


import {Signup as SignupLayout} from '../layouts';


class Login extends Component {

    componentWillReceiveProps = (nextProps, nextContext) => {
        const {user, authenticated} = this.props
        const isAdmin = (nextProps.user && nextProps.user.type === 'admin')
        if ((!authenticated && nextProps.authenticated) && (!user.id && nextProps.user.id)) {
            this.redirect(isAdmin ? '/users' : '/reservations')
        }
        if (!user.id && nextProps.user.redirect) {
            this.redirect(nextProps.user.redirect);
        }
    }

    componentDidMount = () => {
        const {user, authenticated} = this.props
        const isAdmin = (user && user.type === 'admin')
        if (authenticated && user && user.id) {
            this.redirect(isAdmin ? '/users' : '/reservations')
        }
    }

    redirect = (path) => {
        if (!path.startsWith('/login/challenge') && this.props.router.query.next) {
            this.props.router.push(this.props.router.query.next)
        }
        else {
            this.props.router.push(path)
        }
    }

    handleSubmit = async (form) => {
        const {login} = this.props
        try {
            const res = await login(form)
            if (res && res.sessionId) {
                const isAdmin = (res.type === 'admin');
                createCookie(config.cookieName, res.sessionId);
                this.redirect(isAdmin ? '/users' : '/reservations');
            }
        }
        catch (e) {
            throw new SubmissionError({_error: e})
        }
    }

    render() {
        const {loginError} = this.props
        return (
            <SignupLayout withFooter={false}>
                <LoginForm onSubmit={this.handleSubmit} loginError={loginError}/>
            </SignupLayout>
        )
    }
}

Login.propTypes = {
    login: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    authenticated: PropTypes.bool,
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        loaded: state.auth.loaded,
        authenticated: state.auth.authenticated,
        user: state.auth.user,
        loginError: state.auth.error,
    }
}

export {Login}
const ConnectedLogin = connect(mapStateToProps, {login})(Login)
export default ConnectedLogin
