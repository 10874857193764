import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography'


export const Content = styled.div`
  text-align: center;
`

const ErrorBox = ({error}) => {

    if (!error) {
        return null
    }

    return (
        <Content>
            <Typography
                variant="subtitle1"
                color='error'
                gutterBottom
            >
                {error}
            </Typography>
        </Content>
    )
}

ErrorBox.propTypes = {
    error: PropTypes.any,
}

export default ErrorBox
