import {createValidator, required, email, integer, minLength, maxLength, normalizeEmail} from '../../libs/validation';

const LoginFormValidation = createValidator({
    email: [required, email],
    password: [required, minLength(8)],
});

const LoginChallengeFormValidation = createValidator({
    code: [required, integer, minLength(6), maxLength(6)],
});

export default LoginFormValidation;
export {LoginFormValidation, LoginChallengeFormValidation, normalizeEmail};