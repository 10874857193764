import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import {reduxForm, Field} from 'redux-form'

import {LoginFormValidation, normalizeEmail} from './LoginFormValidation'

import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import {withStyles} from '@material-ui/core';

import ErrorBox from '../ErrorBox/ErrorBox'
import TextField from '../Input/TextField'
import Link from '../Link'
import {Spacing, Content, MaxWidthWrapper} from '../Styles'


const styles = theme => ({
    footer: {
        marginTop: '15px',
        display: 'flex',
    },
    footerRight: {
        marginLeft: 'auto'
    }
});

class LoginForm extends Component {
    render() {
        const {classes, handleSubmit, error, loginError, pristine, submitting} = this.props
        return (
            <Container maxWidth="xs">

                {(error || loginError) &&
                <Fragment>
                    <ErrorBox error={error || loginError}/>
                </Fragment>
                }

                <Paper>
                    <Content>
                        <Typography
                            variant="h5"
                            color='primary'
                            gutterBottom
                        >
                            Login to Autohost
                        </Typography>

                        <Spacing/>

                        <form onSubmit={handleSubmit}>

                            <MaxWidthWrapper maxWidth="300px">
                                <Field
                                    name="email"
                                    type="email"
                                    component={TextField}
                                    normalize={normalizeEmail}
                                    label="Email"
                                    placeholder="Enter your email"
                                    fullWidth={true}
                                    inputProps={{
                                        autoComplete: 'username'
                                    }}
                                />

                                <Spacing/>

                                <Field
                                    name="password"
                                    type="password"
                                    component={TextField}
                                    label="Password"
                                    placeholder="Enter your password"
                                    fullWidth={true}
                                    inputProps={{
                                        autoComplete: 'current-password'
                                    }}
                                />

                                <Spacing/>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    fullWidth={true}
                                    disabled={pristine || submitting}
                                >
                                    LOGIN
                                </Button>
                            </MaxWidthWrapper>

                        </form>
                    </Content>
                </Paper>

                <div className={classes.footer}>
                    <div>
                        <Typography>
                            <a href="https://www.autohost.ai/get-started/integrations">Create account</a>
                        </Typography>
                    </div>
                    <div className={classes.footerRight}>
                        <Typography>
                            <Link href="/account/reset">Forgot password?</Link>
                        </Typography>
                    </div>
                </div>
            </Container>
        )
    }
}

LoginForm.propTypes = {
    // from parent
    onSubmit: PropTypes.func.isRequired,
    loginError: PropTypes.any,

    // with-styles
    classes: PropTypes.object.isRequired,

    // redux-form
    error: PropTypes.any,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
}

const ReduxForm = {
    form: 'login',
    validate: LoginFormValidation
}

export {LoginForm}
export default compose(
    reduxForm(ReduxForm),
    withStyles(styles)
)(LoginForm)
