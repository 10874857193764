import React from 'react'
import TextField from '@material-ui/core/TextField'

const renderTextField = ({label, placeholder, input, meta: {touched, warning, invalid, error}, ...custom}) => (
    <TextField
        label={label}
        placeholder={placeholder}
        error={invalid || Boolean(error) || Boolean(warning)}
        {...input}
        {...custom}
        helperText={(invalid && error) || warning || custom.helperText}
    />
)

export default renderTextField